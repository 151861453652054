import React, { useState } from "react";
import "./AdminForgotPassword.css";
// import MainLogo from "../../Assets/Admin LMS Image/DrGarciaLoginLogo.png";
import { Button, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { ActionForgotPassword } from "../../store/actions/actions-user";
import { NotificationContainer } from "react-notifications";

function ForgotPassword() {
  const dispatch = useDispatch();
  const [values, setValues] = useState({ email: "" });
  const [isDisabled, setIsDisabled] = useState(false);
  const handleChange = (e) => {
    setValues({
      ...values,
      email: e.target.value,
    });
  };

  const handleForgotPassword = (e) => {
    e.preventDefault();
    setIsDisabled(true)
    setTimeout(() => {
      setIsDisabled(false)
    }, 3000);
    
    const obj = {
      email: values.email,
      role_id: "1",
    };

    dispatch(ActionForgotPassword(obj));
  };

  return (
    <div id="ForgotPassword">
      <div className="px-md-5 mx-md-5 py-5">
        <div className="px-md-5 mx-5 ">
          <div className="forgotPwdPage text-center">
            <div className="mx-lg-5 px-lg-5 mx-md-0 px-md-0">
              <div className="forgotPwdTitle mt-4 text-uppercase">
                FORGOT PASSWORD?
              </div>
              <div className="mx-lg-5 px-lg-5 mx-md-0 px-md-0">
                <div className="emailForms">
                  <div className="formInput ">
                    <Form onSubmit={handleForgotPassword}>
                      <Form.Group
                        className="text-start"
                        md="4"
                        controlId="validationCustom01"
                      >
                        <Form.Label id="ForgotPwdlabel">Email ID</Form.Label>
                        <Form.Control
                          type="text"
                          value={values.email}
                          onChange={handleChange}
                          className="ForgotPwdInput"
                          placeholder="Enter your Email ID"
                        />
                      </Form.Group>
                      <Button type="submit" className="SubmitForgotpwdBtn mt-5" disabled={isDisabled}>
                        Submit
                      </Button>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <NotificationContainer />
    </div>
  );
}
export default ForgotPassword;
