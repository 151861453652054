import http from "../services/http";
import $q from "q";
import { config } from "../configs";
import { getHeader } from "../store/store";


const { API_MAP, API_URL } = config;

export default class UserAPI {

  static AdminLogin(param) {
    const deferred = $q.defer();
    const header = getHeader();
    http
      .post(API_URL + API_MAP.ADMINLOGIN, param, header)
      .then((res) => deferred.resolve(res))
      .catch((error) => deferred.reject(error));
    return deferred.promise;
  }

  static ForgotPassword(param) {
    const deferred = $q.defer();
    const header = getHeader();
    http
      .post(API_URL + API_MAP.ADMINFORGOTPASSWORD, param,header)
      .then((res) => deferred.resolve(res))
      .catch((error) => deferred.reject(error));
    return deferred.promise;
  }
 
  static RestPassword(param) {
    const deferred = $q.defer();
    const header = getHeader();
    http
      .post(API_URL + API_MAP.ADMINRESETPASSWORD, param, header)
      .then((res) => deferred.resolve(res))
      .catch((error) => deferred.reject(error));
    return deferred.promise;
  }





}