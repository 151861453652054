import React from 'react';
import logo from '../../Assets/Admin LMS Image/DrGarciaLoginLogo.png';
import "./Layout.css";

function Layout({ children, route }) {
    const getClassName = () => {
        switch (route) {
            case 'adminlogin':
                return 'login-layout';
            case 'adminforgotPassword':
                return 'forgotPassword-layout';
            case 'adminresetpassword':
                return 'resetPassword-layout';
            default:
                return '';
        }
    };
    return (
        <div className={` ${getClassName()} initialPage`}>
            <div className="  login-content">
            <div className=" Loginpage text-center">
              <img src={logo} alt="Dr Garcia Logo" id="loginLogo" className='pt-5' />
            </div>
                <div className="layout" >{children}</div>
            </div>
        </div>
    )
}

export default Layout
