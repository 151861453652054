import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { BrowserRouter as Router } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import { useDispatch } from "react-redux";
import Routes from "./Routes";
import "./App.css";
import { store, persistor, history } from "./store/store";
import { ActionLogout } from "./store/actions/actions-user";
import "react-notifications/lib/notifications.css";
import "bootstrap/dist/css/bootstrap.min.css";

function AppContent() {
  const dispatch = useDispatch();

  useEffect(() => {
    let logoutTimeout;

    const startLogoutTimer = () => {
      logoutTimeout = setTimeout(() => {
        dispatch(ActionLogout());
      }, 7200000); // 2 hours in milliseconds
    };

    const resetLogoutTimer = () => {
      clearTimeout(logoutTimeout);
      startLogoutTimer();
    };

    startLogoutTimer();
    
    window.addEventListener("mousemove", resetLogoutTimer);
    window.addEventListener("keypress", resetLogoutTimer);

    return () => {
      clearTimeout(logoutTimeout);
      window.removeEventListener("mousemove", resetLogoutTimer);
      window.removeEventListener("keypress", resetLogoutTimer);
    };
  }, [dispatch]);

  return (
    <ConnectedRouter history={history}>
      <Routes history={history} store={store} />
    </ConnectedRouter>
  );
}

function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <Router>
            <AppContent />
          </Router>
        </PersistGate>
      </Provider>
    </div>
  );
}

export default App;