import http from "../services/http";
import $q from "q";
import { config } from "../configs";
import { getHeader } from "../store/store";

const { API_MAP, API_URL } = config;

export default class ServerData {


  static getDashboard(param) {
    const UserBeareToken = param.token;
    const deferred = $q.defer();
    const header = getHeader();
    header['Authorization'] = 'Bearer ' + UserBeareToken;
    http
      .get(API_URL + API_MAP.ADMINDASHBOARD, header)
      .then((res) => deferred.resolve(res))
      .catch((error) => deferred.reject(error));
    return deferred.promise;
  }

  static getUnlockmodules(param) {
    const UserBeareToken = param.token;
    const deferred = $q.defer();
    const header = getHeader();
    header['Authorization'] = 'Bearer ' + UserBeareToken;
    http
      .get(API_URL + API_MAP.ADMINUNLOCKMODULES, header)
      .then((res) => deferred.resolve(res))
      .catch((error) => deferred.reject(error));
    return deferred.promise;
  }


  static getPaymentStatus(param) {
    const UserBeareToken = param.token;
    const deferred = $q.defer();
    const header = getHeader();
    header['Authorization'] = 'Bearer ' + UserBeareToken;
    http
      .get(API_URL + API_MAP.ADMINPAYMENTSTATUS, header)
      .then((res) => deferred.resolve(res))
      .catch((error) => deferred.reject(error));
    return deferred.promise;
  }

  static getRegisterationDetails(param) {
    const UserBeareToken = param.token;
    const deferred = $q.defer();
    const header = getHeader();
    header['Authorization'] = 'Bearer ' + UserBeareToken;
    http
      .get(API_URL + API_MAP.ADMINREGISTRATIONDETAILS, header)
      .then((res) => deferred.resolve(res))
      .catch((error) => deferred.reject(error));
    return deferred.promise;
  }

  static getCourseDetails(param) {
    const UserBeareToken = param.token;
    const deferred = $q.defer();
    const header = getHeader();
    header['Authorization'] = 'Bearer ' + UserBeareToken;
    http
      .get(API_URL + API_MAP.ADMINCOURSEDETAILS, header)
      .then((res) => deferred.resolve(res))
      .catch((error) => deferred.reject(error));
    return deferred.promise;
  }
  static ProductDetails(params) {
    const deferred = $q.defer();
    const header = getHeader();
    header["Authorization"] = "Bearer " + params.token;
    http
      .post(API_URL + API_MAP.ADMINPRODUCTDETAILS, params, header)
      .then((res) => deferred.resolve(res))
      .catch((error) => deferred.reject(error));

    return deferred.promise;
  }

  static getStudentProgress(param) {
    const UserBeareToken = param.token;
    const deferred = $q.defer();
    const header = getHeader();
    header['Authorization'] = 'Bearer ' + UserBeareToken;
    http
      .get(API_URL + API_MAP.ADMINSTUDENTPROGRESS, header)
      .then((res) => deferred.resolve(res))
      .catch((error) => deferred.reject(error));
    return deferred.promise;
  }

  static PostLockUnlockUser(params) {
    const deferred = $q.defer();
    const header = getHeader();
    header["Authorization"] = "Bearer " + params.token;
    http
      .post(API_URL + API_MAP.ADMINLOCKUNLOCKUSER, params, header)
      .then((res) => deferred.resolve(res))
      .catch((error) => deferred.reject(error));

    return deferred.promise;
  }
}

