import React from "react";
import "./Header.css";
import MainLogo from "../../Assets/Admin LMS Image/DrGarciaLoginLogo.png";
import AdminImage from "../../Assets/Admin LMS Image/AdminImage.png";
import ROUTES from "../../configs/routes";
import { useSelector } from "react-redux";
import SideBar from "../SideBar/SideBar";

export default function Header() {
  const mystate = useSelector((state) => state);
  const serverData = mystate.rServerData?.AdminLogin || [];

  return (
    <div>
      <div className="header d-flex">
        <div className="position-absolute">
          <button
            className="btn d-lg-none"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasLeft"
            aria-controls="offcanvasLeft"
          >
            <i className="fa-solid fa-bars"></i>
            </button>

          <div
            className="offcanvas offcanvas-start w-50 d-lg-none"
            data-bs-scroll="true"
            data-bs-backdrop="false"
            tabIndex="-1"
            id="offcanvasLeft"
            aria-labelledby="offcanvasleftLabel"
          >
            <div className="offcanvas-header d-flex justify-content-between">
              <div className="d-flex adminDetails">
                <img
                  src={serverData?.Admin?.profile_image || AdminImage}
                  id="UserProfileImage"
                  alt=""
                />
                <div className="adminNameAndId">
                  <div className="AdminName">
                    {serverData?.Admin?.firstname || "Admin"}{" "}
                    {serverData?.Admin?.lastname || ""}
                  </div>
                  <div>ID: {serverData?.Admin?.id || ""}</div>
                </div>
              </div>

              <button
                type="button"
                className=" text-reset"
                id="BtnClose"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              >
                <i className="fa-solid fa-x"></i>
              </button>
            </div>
            <div className="offcanvas-body p-0">
              <SideBar/>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 d-flex align-items-center MainLogoDashBoard ps-5">
              <a href={ROUTES.AdminDashboard}>
                <img src={MainLogo} alt="Dr Garcia Logo" id="DashboardLogo" />
              </a>
            </div>
            <div className="col-lg-6 d-none d-lg-flex justify-content-end align-items-center pe-5">
              <div className="leftSideBorder d-block"></div>
              <div className="d-flex adminDetails">
                <img
                  src={serverData?.Admin?.profile_image || AdminImage}
                  id="UserProfileImage"
                  alt=""
                />
                <div className="adminNameAndId">
                  <div className="AdminName">
                    {serverData?.Admin?.firstname || "Admin"}{" "}
                    {serverData?.Admin?.lastname || ""}
                  </div>
                  <div>ID: {serverData?.Admin?.id || ""}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
