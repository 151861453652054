import ServerData from "../../api/server";
import { ActionLoadingUpdate } from "./actions-loading";
import { SERVER_ACTIONS } from "../actions-names/server";
import { ActionOpenNotification } from "./actions-utils-data";
import { ActionRouteNavigate } from "./actions-route";
// import { ActionRouteNavigate } from "./actions-route";

export function ActionServerData(ns, payload) {
  const data = {};
  data[ns] = payload;
  return {
    type: SERVER_ACTIONS.UPDATE_DATA,
    data,
  };
}

export function ActionClearServer() {
  return {
    type: SERVER_ACTIONS.CLEAR,
  };
}

export function ActionClearServerWithKey(key) {
  return (dispatch) => {
    dispatch(ActionServerData(key, undefined));
  };
}
export function ActionDashboard(params) {
  return (dispatch) => {
    dispatch(ActionLoadingUpdate("dashboard", true));
    ServerData.getDashboard(params)
      .then((res) => {
        if (res.success === true) {
          dispatch(ActionServerData("dashboard", res));
        }else if(res.message === "Unauthenticated."){
          dispatch(ActionRouteNavigate("/"))
        } else {
          dispatch(ActionServerData("dashboard", res));
        }
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(ActionLoadingUpdate("dashboard", false)));
  };
}

export function ActionUnlockmodules(params) {
  return (dispatch) => {
    dispatch(ActionLoadingUpdate("orderslistLoading", true));
    ServerData.getUnlockmodules(params)
      .then((res) => {
        if (res.success === true) {
          dispatch(ActionServerData("UnlockModules", res));
        }
        else if(res.message === "Unauthenticated."){
          dispatch(ActionRouteNavigate("/"))
        }  else {
          dispatch(ActionServerData("UnlockModules", res));
        }
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(ActionLoadingUpdate("orderslistLoading", false)));
  };
}

export function ActionPaymentStatus(params) {
  return (dispatch) => {
    dispatch(ActionLoadingUpdate("PaymentStatusLoading", true));
    ServerData.getPaymentStatus(params)
      .then((res) => {
        if (res.success === true) {
          dispatch(ActionServerData("PaymentStatus", res));
        }
        else if(res.message === "Unauthenticated."){
          dispatch(ActionRouteNavigate("/"))
        }  else {
          dispatch(ActionServerData("PaymentStatus", res));
        }
      })
      .catch((err) => console.log(err))
      .finally(() =>
        dispatch(ActionLoadingUpdate("PaymentStatusLoading", false))
      );
  };
}

export function ActionRegisterationDetails(params) {
  return (dispatch) => {
    dispatch(ActionLoadingUpdate("RegisterationLoading", true));
    ServerData.getRegisterationDetails(params)
      .then((res) => {
        if (res.success === true) {
          dispatch(ActionServerData("RegisterationDetails", res));
        }
        else if(res.message === "Unauthenticated."){
          dispatch(ActionRouteNavigate("/"))
        }  else {
          dispatch(ActionServerData("RegisterationDetails", res));
        }
      })
      .catch((err) => console.log(err))
      .finally(() =>
        dispatch(ActionLoadingUpdate("RegisterationLoading", false))
      );
  };
}

export function ActionCourseDetails(params) {
  return (dispatch) => {
    dispatch(ActionLoadingUpdate("CourseDetailsLoading", true));
    ServerData.getCourseDetails(params)
      .then((res) => {
        if (res.success === true) {
          dispatch(ActionServerData("courseDetails", res));
        }
        else if(res.message === "Unauthenticated."){
          dispatch(ActionRouteNavigate("/"))
        }  else {
          dispatch(ActionServerData("courseDetails", res));
        }
      })
      .catch((err) => console.log(err))
      .finally(() =>
        dispatch(ActionLoadingUpdate("CourseDetailsLoading", false))
      );
  };
}

export function ActionProductDetails(param) {
  return (dispatch) => {
    dispatch(ActionLoadingUpdate("customerdetailsLoading", true));
    ServerData.ProductDetails(param)
      .then((res) => {
        if (res.success === true) {
          dispatch(ActionServerData("customerdetails", res));
        }
        else if(res.message === "Unauthenticated."){
          dispatch(ActionRouteNavigate("/"))
        }  else {
          dispatch(ActionServerData("customerdetails", res)); 
        }
      })
      .catch((err) => console.log(err))
      .finally(() =>
        dispatch(ActionLoadingUpdate("customerdetailsLoading", false))
      );
  };
}

export function ActionStudentProgress(params) {
  return (dispatch) => {
    dispatch(ActionLoadingUpdate("StudentProgressLoading", true));
    ServerData.getStudentProgress(params)
      .then((res) => {
        if (res.success === true) {
          dispatch(ActionServerData("studentprogress", res));
        }
        else if(res.message === "Unauthenticated."){
          dispatch(ActionRouteNavigate("/"))
        }  else {
          dispatch(ActionServerData("studentprogress", res));
        }
      })
      .catch((err) => console.log(err))
      .finally(() =>
        dispatch(ActionLoadingUpdate("studentprogress", false))
      );
  };
}

export function ActionVerifyCheckEmail(params) {
  return (dispatch) => {
    dispatch(ActionLoadingUpdate("checkEmail", true));
    ServerData.checkEmail(params)
      .then((res) => {
        if (res.success === true) {
          dispatch(ActionServerData("checkEmail", res.data));
        }
         else {
          dispatch(ActionServerData("checkEmail", res.data));
          dispatch(ActionOpenNotification("info", "failed", res.message));
        }
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(ActionLoadingUpdate("checkEmail", false)));
  };
}

export function ActiongetUserToken(userToken) {
  return (dispatch) => {
    dispatch(ActionServerData("Usertoken", userToken));
  };
}

export function ActionLockUnlockUser(params) {
  return (dispatch) => {
    dispatch(ActionLoadingUpdate("LockUnlockUserLoading", true));
    ServerData.PostLockUnlockUser(params)
      .then((res) => {
        if (res.success === true) {
          dispatch(ActionServerData("LockUnlockUser", res.data));
        } else {
          dispatch(ActionServerData("LockUnlockUser", res.data));
        }
      })
      .catch((err) => console.log(err))
      .finally(() =>
        dispatch(ActionLoadingUpdate("LockUnlockUserLoading", false))
      );
  };
}
