import React, { useState } from "react";
import { Modal } from "react-bootstrap";

const ProductModel = ({ show, handleClose, modalData, CustomerName }) => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size="xl"
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Product Information of{" "}
          <span className="fw-bold" style={{ fontSize: "30px" }}>
            {CustomerName}
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ maxHeight: "500px", overflowY: "auto" }}>
        {modalData.map((item, index) => (
          <div
            key={`${item.productId}-${index}`}
            className=" my-3 accordion accordion-flush p-1"
            id="accordionFlushExample"
            style={{
              border: "none",
              borderRadius: "10px",
              boxShadow: "0 0px 1px black",
            }}
          >
            <div
              className="card-title ms-4 m-3 d-flex justify-content-between"
              onClick={() => toggleAccordion(index)}
              style={{ cursor: "pointer" }}
            >
              <h5>Order Id: {item.productOrderId}</h5>
              <div
                className="me-3"
                style={{
                  transition: "transform 2s ease",
                }}
              >
                {openIndex === index ? (
                  <i className="fa-solid fa-angle-up"></i>
                ) : (
                  <i className="fa-solid fa-angle-down"></i>
                )}
              </div>
            </div>
            <div className="accordion-content">
              {openIndex === index && (
                <div className="card-body mt-0 pt-0 text-center" style={{ overflowX: "auto" }}>
                  <table className="table">
                    <thead>
                      <tr>
                        <th style={{ whiteSpace: "nowrap" }}>S.No</th>
                        <th style={{ whiteSpace: "nowrap" }}>Product Name</th>
                        <th style={{ whiteSpace: "nowrap" }}>
                          Product Quantity
                        </th>
                        <th style={{ whiteSpace: "nowrap" }}>Product Price</th>
                        <th style={{ whiteSpace: "nowrap" }}>Total Price</th>
                        <th style={{ whiteSpace: "nowrap" }}>
                          Purchasing Date
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {item.subItems.map((subItem, subIndex) => (
                        <tr key={`${item.productId}-${subIndex}`}>
                          <td>{subIndex + 1}</td>
                          <td>{subItem.productName}</td>
                          <td>{subItem.productQty}</td>
                          <td>
                            {(
                              subItem.productPrice / subItem.productQty
                            ).toFixed(2)}
                          </td>
                          <td>{subItem.totalProductPrice.toFixed(2)}</td>
                          <td>{subItem.purchasedDate}</td>
                        </tr>
                      ))}
                      <tr>
                        <td colSpan="3" className="text-start" style={{ textAlign: "right" }}>
                         <span className="me-1 ms-3"> Total Shipping Charge =</span>
                        <span className="fw-bold"> ${item.totalShippingCharge}</span>
                        </td>
                        <td colSpan="3" style={{ textAlign: "right" }}>
                        <span className="me-1 ms-3">Total Billing Charge =</span>
                        <span className="fw-bold me-3">${item.totalBillingCharge + item.totalShippingCharge }</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        ))}
      </Modal.Body>
    </Modal>
  );
};

export default ProductModel;
