import React, { useEffect, useMemo, useState } from "react";
import "./StudentProgress.css";
import { Button, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { ActionStudentProgress } from "../../store/actions/actions-server-data";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import "flatpickr/dist/themes/light.css";
import SiderIcons from "../../components/SVG Icons/Svg";

export default function StudentProgress() {
  const dispatch = useDispatch();
  const mystate = useSelector((state) => state);
  const ServerData = mystate?.rServerData;
  const isLoading = mystate?.rLoading;
  const callAPILoad = isLoading?.PaymentStatusLoading;
  const userAccessToken = ServerData?.userAccessToken || null;
  const studentProgress = useMemo(
    () => ServerData?.studentprogress?.data || [],
    [ServerData]
  );

  const itemsPerPage = 8;

  const [studentfilteredData, setStudentFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [CustomerStudentData, setCustomerStudentData] = useState("");
  const [initialData, setInitialData] = useState([]);

  useEffect(() => {
    dispatch(ActionStudentProgress({ token: userAccessToken }));
  }, [dispatch, userAccessToken]);

  useEffect(() => {
    const reversedData = [...studentProgress].reverse();
    setInitialData(reversedData);
    setStudentFilteredData(reversedData);
  }, [studentProgress]);



  const timeDateFormate = (timestamp) => {
    let date = new Date(timestamp);
    return date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let filteredResult = initialData;

    if (CustomerStudentData) {
      filteredResult = filteredResult.filter(
        (item) =>
          item.student_email
            .toLowerCase()
            .includes(CustomerStudentData.trim().toLowerCase()) ||
          item.userid.toString().includes(CustomerStudentData.trim()) ||
          item.firstname
            .toLowerCase()
            .includes(CustomerStudentData.trim().toLowerCase()) ||
          item.lastname
            .toLowerCase()
            .includes(CustomerStudentData.trim().toLowerCase()) ||
          `${item.firstname.toLowerCase()} ${item.lastname.toLowerCase()}`.includes(
            CustomerStudentData.trim().toLowerCase()
          )
      );
    }

    setStudentFilteredData(filteredResult);
    setCurrentPage(1);
  };

  const splitSublecture = (sublectureid) => {
    if (!sublectureid) return "NA";
    const datePart = sublectureid.split("-")[1];
    const addDataPart = Number(datePart) + 1;
    return addDataPart;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "CustomerStudentData") {
      setCustomerStudentData(value);
    }
  };

  const handelResetBtn = () => {
    setCustomerStudentData("");
    setStudentFilteredData(studentProgress);
    setCurrentPage(1);
  };

  const paginatedData = studentfilteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const totalPages = Math.ceil(studentfilteredData.length / itemsPerPage);

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const getPageRange = (currentPage) => {
    const start = Math.floor((currentPage - 1) / 10) * 10 + 1;
    const end = Math.min(start + 9, totalPages);
    return { start, end };
  };

  return (
    <div>
      {callAPILoad && studentProgress.length === 0 ? (
        <div className="payment_overlay">
          <div className="d-flex align-items-center justify-content-center flex-column">
            <FontAwesomeIcon
              icon={faSpinner}
              className="fa-spin"
              style={{ fontSize: "40px", color: "#000" }}
            />
            <p className="mt-2 payment_loading">Please wait...</p>
          </div>
        </div>
      ) : (
        <div className="StudentProgressPage ms-md-3 ms-sm-0">
          <div className="filterOption d-flex ">
            <div className="container-fluid">
              <form onSubmit={handleSubmit}>
                <Row>
                  <Col sm={10} lg={9} className="p-0">
                    <div className="">
                      <div className="">
                        <div className="form-group">
                          <input
                            type="text"
                            name="CustomerStudentData"
                            className="form-control PaymentInputValue"
                            id="PaymentInputValue"
                            value={CustomerStudentData}
                            onChange={handleChange}
                            placeholder="Search by email ID, name or Student ID"
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col sm={2} lg={3}>
                    <div className="">
                      <div className="">
                        <div className="d-flex justify-content-between">
                          <div>
                            <button
                              type="submit"
                              id="filterSubmitBtn"
                              className="btn btn-primary ms-3 filterSubmitBtnStudent"
                            >
                              search
                            </button>
                            <button
                              type="submit"
                              className="btn smallScreen"
                              id="filterSubmitIcons"
                            >
                              <SiderIcons icons={8} />
                            </button>
                          </div>
                          <div className="ResetBtn">
                            <button
                              className="btn "
                              id="ResetBtnStudent"
                              onClick={handelResetBtn}
                            >
                              <span id="reset">Reset</span>
                              <span id="reseticon">
                                <i className="fa-solid fa-rotate-right"></i>
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </form>
            </div>
          </div>
          {studentfilteredData.length !== 0 ? (
            <div>
              <div className="" style={{ overflowX: "auto" }}>
                <div className="outputTables table text-center mt-4 mb-2">
                  <table className="col-12">
                    <thead
                      style={{ backgroundColor: "#402F7B", color: "white" }}
                    >
                      <tr id="tableHead">
                        <th style={{ whiteSpace: "nowrap" }}>Order ID</th>
                        <th style={{ whiteSpace: "nowrap" }}>Student ID</th>
                        <th style={{ whiteSpace: "nowrap" }}>Name</th>
                        <th style={{ whiteSpace: "nowrap" }}>Email ID</th>
                        <th style={{ whiteSpace: "nowrap" }}>Modules</th>
                        <th style={{ whiteSpace: "nowrap" }}>Lecture</th>
                        <th style={{ whiteSpace: "nowrap" }}>Sub Lecture</th>
                        <th style={{ whiteSpace: "nowrap" }}>
                          Assessment Session
                        </th>
                        <th style={{ whiteSpace: "nowrap" }}>
                          Assessment Lecturer
                        </th>
                        <th style={{ whiteSpace: "nowrap" }}>Last visit</th>
                      </tr>
                    </thead>
                    <tbody>
                      {paginatedData.map((item, index) => (
                        <tr key={index} id="tableData">
                          <td style={{ whiteSpace: "nowrap" }}>
                            {item.orderid || "NA"}
                          </td>
                          <td style={{ whiteSpace: "nowrap" }}>
                            {item.userid || "NA"}
                          </td>
                          <td style={{ whiteSpace: "nowrap" }}>
                            {`${item.firstname} ${item.lastname}` || "NA"}
                          </td>
                          <td style={{ whiteSpace: "nowrap" }}>
                            {item.student_email || "NA"}
                          </td>
                          <td style={{ whiteSpace: "nowrap" }}>
                            {item.sessionid || "NA"}
                          </td>
                          <td style={{ whiteSpace: "nowrap" }}>
                            {item.lectureid || "NA"}
                          </td>
                          <td style={{ whiteSpace: "nowrap" }}>
                            {splitSublecture(item.sublectureid)}
                          </td>
                          <td style={{ whiteSpace: "nowrap" }}>
                            {item.assessment_sessionid || "NA"}
                          </td>
                          <td style={{ whiteSpace: "nowrap" }}>
                            {item.assessment_lecturerid || "NA"}
                          </td>
                          <td style={{ whiteSpace: "nowrap" }}>
                            {item.updatedat
                              ? timeDateFormate(item.updatedat)
                              : "NA"}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="d-flex justify-content-center mt-3">
                {studentProgress.length > 8 && itemsPerPage && (
                  <div className="d-flex align-items-center">
                    {/* Previous Button */}
                    {totalPages > 1 && (
                      <Button
                        variant="link"
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                        className="pagination-button"
                        style={{
                          color: "#000",
                          border: "none",
                          textDecoration: "none",
                        }}
                      >
                        <i className="fa-solid fa-backward-step"></i> Prev
                      </Button>
                    )}

                    {/* Page Numbers */}
                    <div className="d-flex text-center align-items-center">
                      {Array.from(
                        {
                          length:
                            getPageRange(currentPage).end -
                            getPageRange(currentPage).start +
                            1,
                        },
                        (_, i) => {
                          const page = getPageRange(currentPage).start + i;

                          return (
                            <Button
                              key={page}
                              variant={
                                page === currentPage ? "primary" : "link"
                              }
                              onClick={() => handlePageChange(page)}
                              disabled={page === currentPage}
                              className={`mx-1 pagination-button ${
                                page === currentPage ? "active" : ""
                              }`}
                              style={{
                                backgroundColor:
                                  totalPages > 1 && page === currentPage
                                    ? "#402F7B"
                                    : "transparent",
                                color: page === currentPage ? "#fff" : "#000",
                                border: "none",
                                textDecoration: "none",
                              }}
                            >
                              {totalPages > 1 && page}
                            </Button>
                          );
                        }
                      )}

                      {/* Ellipsis */}
                      {totalPages >= 10 &&
                        currentPage <= Math.floor(totalPages / 10) * 10 && (
                          <span>...</span>
                        )}

                      {/* Last Page Button */}
                      {totalPages > 10 && (
                        <Button
                          variant="link"
                          onClick={() => handlePageChange(totalPages)}
                          disabled={currentPage === totalPages}
                          className="pagination-button"
                          style={{
                            color: "#000",
                            border: "none",
                            textDecoration: "none",
                          }}
                        >
                          {currentPage <= Math.floor(totalPages / 10) * 10
                            ? totalPages
                            : ""}
                        </Button>
                      )}
                    </div>
                    {/* Next Button */}
                    {totalPages > 1 && (
                      <Button
                        variant="link"
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                        className="pagination-button"
                        style={{
                          color: "#000",
                          border: "none",
                          textDecoration: "none",
                        }}
                      >
                        Next <i className="fa-solid fa-forward-step"></i>
                      </Button>
                    )}
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div
              className="text-center mt-5"
              style={{ fontSize: "20px", fontWeight: "500" }}
            >
              <p>No Data Found
              <FontAwesomeIcon
                icon={faSpinner}
                className="fa-spin"
                style={{ fontSize: "40px", color: "#000",margin:"0" }}
              /></p>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
