import React from "react";
import { Redirect, Route, Switch } from "react-router";
import ROUTES from "./configs/routes";
import AdminLogin from "./Pages/LoginPage/AdminLogin";
import AdminForgotPassword from "./Pages/AdminForgotPassword/AdminForgotPassword";
import Layout from "./Pages/Layout/Layout";
import ResetPassword from "./Pages/PasswordResetScreen/PasswordResetScreen";
import Dashboard from "./Pages/Dashboard/Dashboard";
import StaticLayout from "./Pages/StaticLayout/StaticLayout";
import UnlockModules from "./Pages/UnlockModules/UnlockModules";
import PaymentStatus from "./Pages/PaymentStatus/PaymentStatus";
import RegistrationDetails from "./Pages/Registration Details/RegistrationDetails";
import CourseDetails from "./Pages/Course Details/CourseDetails";
import CustomerDetails from "./Pages/CustomerDetails/CustomerDetails";
import { useSelector } from "react-redux";
import StudentProgress from "./Pages/StudentProgress/StudentProgress";
import ProductDetails from "./Pages/ProductDetails/ProductDetails";

const Routes = (props) => {
  const isAuthenticated = useSelector((state) => state);
  const UserAccessToken = isAuthenticated?.rServerData?.userAccessToken || null;
  const resetPassword = isAuthenticated?.rServerData?.ForgotPassword?.oldpassword || null;

  return (
    <Switch>
      <Route exact path={ROUTES.AdminLogin}>
        <Layout route="adminLogin">
          <AdminLogin />
        </Layout>
      </Route>

      <Route exact path={ROUTES.AdminForgotPassword}>
        <Layout route="adminForgotPassword">
          <AdminForgotPassword />
        </Layout>
      </Route>

      <Route exact path={ROUTES.AdminUpdatepassword}>
        {resetPassword ? (
             <Route exact path={ROUTES.AdminUpdatepassword}>
             <Layout route="adminUpdatepassword">
               <ResetPassword />
             </Layout>
           </Route>
        ) : (
          <Redirect to={"adminForgotPassword"} />
        )}
      </Route>

      {UserAccessToken && UserAccessToken.length > 0 ? (
       <div>
         <Route exact path={ROUTES.AdminDashboard}>
          <StaticLayout children={<Dashboard />} />
        </Route>

        <Route exact path={ROUTES.AdminUnlockModules}>
          <StaticLayout children={<UnlockModules />} />
        </Route>

        <Route exact path={ROUTES.AdminPaymentStatus}>
          <StaticLayout children={<PaymentStatus />} />
        </Route>

        <Route exact path={ROUTES.AdminRegistrationDetails}>
          <StaticLayout children={<RegistrationDetails />} />
        </Route>

        <Route exact path={ROUTES.AdminCourseDetails}>
          <StaticLayout children={<CourseDetails />} />
        </Route>

        <Route exact path={ROUTES.AdminCustomerDetails}>
          <StaticLayout children={<CustomerDetails />} />
        </Route>
        <Route exact path={ROUTES.AdminProductDetails}>
          <StaticLayout children={<ProductDetails />} />
        </Route>
        <Route exact path={ROUTES.AdminStudentProgress}>
          <StaticLayout children={<StudentProgress />} />
        </Route>

       </div>
      ) : (
        <Redirect to={"/"} />
      )}


    </Switch>
  );
};

export default Routes;
