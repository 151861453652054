import React, { useEffect, useMemo, useState } from "react";
import "./CourseDetails.css";
import { Button, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import ExportBtnIcons from "../../Assets/Admin LMS Image/Icons/page-csv.svg";
import { ActionCourseDetails } from "../../store/actions/actions-server-data";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/light.css";
import SiderIcons from "../../components/SVG Icons/Svg";
import { config } from "../../configs/index";

export default function CourseDetails() {

  const {  API_URL } = config;
  const dispatch = useDispatch();
  const mystate = useSelector((state) => state);
  const ServerData = mystate?.rServerData;
  const isLoading = mystate?.rLoading;
  const callAPILoad = isLoading?.CourseDetailsLoading;
  const userAccessToken = ServerData?.userAccessToken || null;
  const CourseDetails = useMemo(() => ServerData?.courseDetails?.data || [], [ServerData]);

  const itemsPerPage = 8;

  const [courseDetailsfilteredData, setCourseDetailsfilteredData] = useState(
    []
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [CustomerCourseData, setCustomerCourseData] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [initialData, setInitialData] = useState([]);
  const [fromDatePicker, setFromDatePicker] = useState(null);
  const [toDatePicker, setToDatePicker] = useState(null);

  useEffect(() => {
    dispatch(ActionCourseDetails({ token: userAccessToken }));
  }, [dispatch,userAccessToken]);

  useEffect(() => {
    const reversedData = [...CourseDetails];
    setInitialData(reversedData);
    setCourseDetailsfilteredData(reversedData);
  }, [CourseDetails]);

  useEffect(() => {
    if (fromDatePicker && toDatePicker && fromDate) {
      toDatePicker.set("minDate", fromDate);
    }
  }, [fromDate, fromDatePicker, toDatePicker]);

  const timeDateFormate = (timestamp) => {
    let date = new Date(timestamp);
    return date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let filteredResult = initialData;
    if (CustomerCourseData) {
      filteredResult = filteredResult.filter(
        (item) =>
          item.CustomerEmail.toLowerCase().includes(
            CustomerCourseData.trim().toLowerCase()
          ) ||
          item.userId === CustomerCourseData.trim() ||
          item.Firstname.toLowerCase().includes(
            CustomerCourseData.trim().toLowerCase()
          ) ||
          item.Lastname.toLowerCase().includes(
            CustomerCourseData.trim().toLowerCase()
          ) ||
          `${item.Firstname.toLowerCase()} ${item.Lastname.toLowerCase()}`.includes(
            CustomerCourseData.trim().toLowerCase()
          )
      );
    }
    if (fromDate) {
      const fromdate = new Date(fromDate);
    
      if (toDate) {
        const todate = new Date(toDate);
        todate.setHours(23, 59, 59, 999);
    
        filteredResult = filteredResult.filter((item) => {
          const itemDate = new Date(item.PurchasedOn);
          return itemDate >= fromdate && itemDate <= todate;
        });
      } else {
        if (toDatePicker && !CustomerCourseData) {
          toDatePicker.open();
        }
      }
    } else if (toDate) {
      if (fromDatePicker && !CustomerCourseData) {
        fromDatePicker.open();
      }
    }
    setCourseDetailsfilteredData(filteredResult);
    setCurrentPage(1);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "fromDate") {
      setFromDate(value);
    } else if (name === "toDate") {
      setToDate(value);
    } else if (name === "CustomerCourseData") {
      setCustomerCourseData(value);
    }
  };

  const handleExportBtnClick = () => {
    window.open(
      `${API_URL}/getCourseDetails_csv`,
      "_blank",
      "noopener,noreferrer"
    );
  };

  const handelResetBtn = () => {
    setCustomerCourseData("");
    setFromDate("");
    setToDate("");
    setCourseDetailsfilteredData(initialData);
    setCurrentPage(1);
  };

  const paginatedData = courseDetailsfilteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const totalPages = Math.ceil(courseDetailsfilteredData.length / itemsPerPage);

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const getPageRange = (currentPage) => {
    const start = Math.floor((currentPage - 1) / 10) * 10 + 1;
    const end = Math.min(start + 9, totalPages);
    return { start, end };
  };

  return (
    <div>
      {callAPILoad && CourseDetails.length === 0 ? (
        <div className="payment_overlay">
          <div className="d-flex align-items-center justify-content-center flex-column">
            <FontAwesomeIcon
              icon={faSpinner}
              className="fa-spin"
              style={{ fontSize: "40px", color: "#000" }}
            />
            <p className="mt-2 payment_loading">Please wait...</p>
          </div>
        </div>
      ) : (
        <div className="ms-md-3 ms-sm-0">
          <div className="filterOption d-flex ">
            <div className="container-fluid">
              <form onSubmit={handleSubmit}>
                <Row>
                  <Col sm={3} lg={5} className="p-0">
                    <div className=" ">
                      <div className="">
                        <div className="form-group">
                          <input
                            type="text"
                            name="CustomerCourseData"
                            className="form-control PaymentInputValue"
                            id="PaymentInputValue"
                            value={CustomerCourseData}
                            onChange={handleChange}
                            placeholder="Search by email ID, name or customer ID"
                          />
                        </div>
                        <div></div>
                      </div>
                    </div>
                  </Col>
                  <Col sm={9} lg={7}>
                    <div className="d-flex justify-content-between">
                      <div className="ms-0">
                        <div className="d-flex justify-content-between">
                          <div className="d-flex">
                            <div className="me-3 position-relative">
                              <Flatpickr
                                className="form-control"
                                placeholder="Purchase From"
                                options={{
                                  dateFormat: "Y-m-d",
                                  altFormat: "d M Y",
                                  altInput: true,
                                  allowInput: true,
                                  onReady: function (
                                    selectedDates,
                                    dateStr,
                                    instance
                                  ) {
                                    setFromDatePicker(instance);
                                  },
                                }}
                                value={fromDate}
                                onChange={([date]) => setFromDate(date)}
                              />
                            </div>

                            <div className=" position-relative">
                              <Flatpickr
                                className="form-control"
                                placeholder="Purchase To"
                                options={{
                                  dateFormat: "Y-m-d",
                                  altFormat: "d M Y",
                                  altInput: true,
                                  allowInput: true,
                                  onReady: function (
                                    selectedDates,
                                    dateStr,
                                    instance
                                  ) {
                                    setToDatePicker(instance);
                                  },
                                }}
                                value={toDate}
                                onChange={([date]) => setToDate(date)}
                              />
                            </div>
                          </div>
                          <div>
                            <button
                              type="submit"
                              id="filterSubmitBtn"
                              className="btn btn-primary ms-3"
                            >
                              search
                            </button>
                            <button
                              type="submit"
                              className="btn smallScreen ms-3"
                              id="filterSubmitIcons"
                            >
                              <SiderIcons icons={8} />
                            </button>
                          </div>
                          <div className="ResetBtn">
                            <button
                              className="btn "
                              id="ResetBtn"
                              onClick={handelResetBtn}
                            >
                              <span id="reset">Reset</span>
                              <span id="reseticon">
                                <i className="fa-solid fa-rotate-right"></i>
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between">
                        <div className="exportPaymentStatusBtn ms-3">
                          <button
                            className="exportPaymentStatusBtn px-3"
                            onClick={handleExportBtnClick}
                          >
                            <span className="largescreen">
                              <img
                                src={ExportBtnIcons}
                                alt="Export Icon"
                                className="pe-2"
                                width="18"
                              />
                              Export
                            </span>
                            <span className="smallScreen">
                              <SiderIcons icons={9} />
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </form>
            </div>
          </div>
          {courseDetailsfilteredData.length === 0 ? (
            <div
              className="text-center mt-5"
              style={{ fontSize: "20px", fontWeight: "500" }}
            >
              <p> No Data Found</p>
            </div>
          ) : (
            <div>
              <div
                className="outputTables table text-center mt-4"
                style={{ overflowX: "auto" }}
              >
                <table className="col-12">
                  <thead style={{ backgroundColor: "#402F7B", color: "white" }}>
                    <tr id="tableHead">
                      <th style={{ whiteSpace: "nowrap" }}>Purchase Date</th>
                      <th style={{ whiteSpace: "nowrap" }}>Order ID</th>
                      <th style={{ whiteSpace: "nowrap" }}>Customer ID</th>
                      <th style={{ whiteSpace: "nowrap" }}>Name</th>
                      <th style={{ whiteSpace: "nowrap" }}>Email ID</th>
                      <th style={{ whiteSpace: "nowrap" }}>Expire Date</th>
                      <th style={{ whiteSpace: "nowrap" }}>Modules</th>
                      <th style={{ whiteSpace: "nowrap" }}>Lecture Progress</th>
                      <th style={{ whiteSpace: "nowrap" }}>Course Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {paginatedData.map((item, index) => (
                      <tr key={index} id="tableData">
                        <td style={{ whiteSpace: "nowrap" }}>
                          {timeDateFormate(item.PurchasedOn || "NA")}
                        </td>
                        <td style={{ whiteSpace: "nowrap" }}>
                          {item.OrderId || "NA"}
                        </td>
                        <td style={{ whiteSpace: "nowrap" }}>
                          {item.userId || "NA"}
                        </td>
                        <td style={{ whiteSpace: "nowrap" }}>
                          {item.Firstname + " " + item.Lastname || "NA"}
                        </td>
                        <td style={{ whiteSpace: "nowrap" }}>
                          {item.CustomerEmail || "NA"}
                        </td>
                        <td style={{ whiteSpace: "nowrap" }}>
                          {timeDateFormate(item.ExpirationOn || "NA")}
                        </td>
                        <td style={{ whiteSpace: "nowrap" }}>
                          {item.SessionProgress || "NA"}
                        </td>
                        <td style={{ whiteSpace: "nowrap" }}>
                          {item.LectureProgress || "NA"}
                        </td>
                        <td style={{ whiteSpace: "nowrap" }}>
                          {item.SessionProgress === "6" &&
                          item.LectureProgress === "57" ? (
                            <div style={{ color: "green", fontWeight: "bold" }}>
                              {" "}
                              Completed{" "}
                            </div>
                          ) : (
                            (
                              <div style={{ color: "red", fontWeight: "bold" }}>
                                Inprogress{" "}
                              </div>
                            ) || "NA"
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="d-flex justify-content-center">
                {CourseDetails.length > 9 && (
                  <div className="d-flex align-items-center">
                    {/* Previous Button */}
                    {totalPages > 1 && (
                      <Button
                        variant="link"
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                        className="pagination-button"
                        style={{
                          color: "#000",
                          border: "none",
                          textDecoration: "none",
                        }}
                      >
                        <i className="fa-solid fa-backward-step"></i> Prev
                      </Button>
                    )}

                    <div className="d-flex text-center align-items-center">
                      {Array.from(
                        {
                          length:
                            getPageRange(currentPage).end -
                            getPageRange(currentPage).start +
                            1,
                        },
                        (_, i) => {
                          const page = getPageRange(currentPage).start + i;

                          return (
                            <Button
                              key={page}
                              variant={
                                page === currentPage ? "primary" : "link"
                              }
                              onClick={() => handlePageChange(page)}
                              disabled={page === currentPage}
                              className={`mx-1 pagination-button ${
                                page === currentPage ? "active" : ""
                              }`}
                              style={{
                                backgroundColor:
                                  totalPages > 1 && page === currentPage
                                    ? "#402F7B"
                                    : "transparent",
                                color: page === currentPage ? "#fff" : "#000",
                                border: "none",
                                textDecoration: "none",
                              }}
                            >
                              {totalPages > 1 && page}
                            </Button>
                          );
                        }
                      )}
                      {totalPages >= 10 &&
                        currentPage <= Math.floor(totalPages / 10) * 10 && (
                          <span>...</span>
                        )}
                      {totalPages > 10 && (
                        <Button
                          variant="link"
                          onClick={() => handlePageChange(totalPages)}
                          disabled={currentPage === totalPages}
                          className="pagination-button"
                          style={{
                            color: "#000",
                            border: "none",
                            textDecoration: "none",
                          }}
                        >
                          {currentPage <= Math.floor(totalPages / 10) * 10
                            ? totalPages
                            : ""}
                        </Button>
                      )}
                    </div>
                    {/* Next Button */}
                    {totalPages > 1 && (
                      <Button
                        variant="link"
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                        className="pagination-button"
                        style={{
                          color: "#000",
                          border: "none",
                          textDecoration: "none",
                        }}
                      >
                        Next <i className="fa-solid fa-forward-step"></i>
                      </Button>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
