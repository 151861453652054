import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import SiderIcons from "../../components/SVG Icons/Svg";
import LogOutIcons from "../../Assets/Admin LMS Image/Icons/logoutIcon.svg";
import ROUTES from "../../configs/routes";
import { ActionLogout } from "../../store/actions/actions-user";
import "./SideBar.css";

export default function SideBar({ bodyLength }) {
  const [activeBar, setActiveBar] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    setActiveBar(window.location.pathname);
  }, []);

  const handleLogout = () => {
    dispatch(ActionLogout());
  };

  return (
    <div id="sideNavBar">
      <div className="sideNavBar d-flex flex-column ">
        <div className="Contants mt-2 text-start d-md-block">
          <ul className="text-capitalize sidebarContants p-0">
            <li
              className={activeBar === ROUTES.AdminDashboard ? "activeBar" : ""}
              onClick={() => setActiveBar(ROUTES.AdminDashboard)}
            >
              <a href={ROUTES.AdminDashboard} className="sideBarLink">
                <div className="sideBarContent">
                  <SiderIcons
                    icons={1}
                    active={activeBar === ROUTES.AdminDashboard}
                  />
                  <span className="ps-3">dashboard</span>
                </div>
              </a>
            </li>

            <li
              className={
                activeBar === ROUTES.AdminUnlockModules ? "activeBar " : ""
              }
              onClick={() => setActiveBar(ROUTES.AdminUnlockModules)}
            >
              <a className="sideBarLink" href={ROUTES.AdminUnlockModules}>
                <div className="sideBarContent">
                  <SiderIcons
                    icons={2}
                    active={activeBar === ROUTES.AdminUnlockModules}
                  />
                  <span className="ps-3">unlock modules</span>
                </div>
              </a>
            </li>

            <li
              className={
                activeBar === ROUTES.AdminPaymentStatus ? "activeBar " : ""
              }
              onClick={() => setActiveBar(ROUTES.AdminPaymentStatus)}
            >
              <a className="sideBarLink" href={ROUTES.AdminPaymentStatus}>
                <div className="sideBarContent">
                  <SiderIcons
                    icons={3}
                    active={activeBar === ROUTES.AdminPaymentStatus}
                  />
                  <span className="ps-3">payment status</span>
                </div>
              </a>
            </li>

            <li
              className={
                activeBar === ROUTES.AdminRegistrationDetails
                  ? "activeBar "
                  : ""
              }
              onClick={() => setActiveBar(ROUTES.AdminRegistrationDetails)}
            >
              <a className="sideBarLink" href={ROUTES.AdminRegistrationDetails}>
                <div className="sideBarContent">
                  <SiderIcons
                    icons={4}
                    active={activeBar === ROUTES.AdminRegistrationDetails}
                  />
                  <span className="ps-3">registration details</span>
                </div>
              </a>
            </li>

            <li
              className={
                activeBar === ROUTES.AdminCourseDetails ? "activeBar " : ""
              }
              onClick={() => setActiveBar(ROUTES.AdminCourseDetails)}
            >
              <a className="sideBarLink" href={ROUTES.AdminCourseDetails}>
                <div className="sideBarContent">
                  <SiderIcons
                    icons={5}
                    active={activeBar === ROUTES.AdminCourseDetails}
                  />
                  <span className="ps-3">course details</span>
                </div>
              </a>
            </li>
            <li
              className={
                activeBar === ROUTES.AdminStudentProgress ? "activeBar " : ""
              }
              onClick={() => setActiveBar(ROUTES.AdminStudentProgress)}
            >
              <a className="sideBarLink" href={ROUTES.AdminStudentProgress}>
                  <div className="sideBarContent">
                    <SiderIcons
                      icons={7}
                      active={activeBar === ROUTES.AdminStudentProgress}
                    />
                    <span className="ps-3">Student Progress</span>
                  </div>
              </a>
            </li>
            <li
              className={
                activeBar === ROUTES.AdminProductDetails ? "activeBar " : ""
              }
              onClick={() => setActiveBar(ROUTES.AdminProductDetails)}
            >
              <a className="sideBarLink" href={ROUTES.AdminProductDetails}>
                <div className="sideBarContent">
                  <SiderIcons
                    icons={11}
                    active={activeBar === ROUTES.AdminProductDetails}
                  />
                  <span className="ps-3">Product Details</span>
                </div>
              </a>
            </li>
          </ul>
        </div>
        <div className="Logout py-5">
          <button className="LogoutBtn" onClick={handleLogout}>
            <img src={LogOutIcons} alt="Logout" />{" "}
            <span className="px-2">Logout</span>
          </button>
        </div>
      </div>
    </div>
  );
}
