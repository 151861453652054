import React, { useEffect, useRef, useState } from "react";
import "./StaticLayout.css";
import Header from "../Header/Header";
import SideBar from "../SideBar/SideBar";

export default function StaticLayout({ children }) {
  const headerRef = useRef(null);
  const [headerHeight, setHeaderHeight] = useState(0);

  useEffect(() => {
    if (headerRef.current) {
      const SideBarHeight = headerRef.current.getBoundingClientRect().height;
      setHeaderHeight(SideBarHeight);
    }
  }, []);

  return (
    <div>
      <div className="HeaderPage" ref={headerRef}>
        <Header />
      </div>
      <div className="w-100 d-flex mt-2" style={{ marginTop: headerHeight }}>
        <div className="leftSideNavbar d-none d-lg-block" style={{height: `calc(100vh - ${headerHeight}px)`,  overflowY:"hidden"}} >
          <SideBar />
        </div>
        <div className="RightSideMainpage px-4 py-3">{children}</div>
      </div>
    </div>
  );
}
