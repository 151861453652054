import { URL_MAP } from "../constants/urls";
import { generatePath } from "react-router";

const {
  BASE_URL,
  AdminLogin,
  AdminForgotPassword,
  AdminUpdatepassword,
  AdminDashboard,
  AdminUnlockModules,
  AdminPaymentStatus,
  AdminRegistrationDetails,
  AdminCourseDetails,
  AdminCustomerDetails,
  AdminProductDetails,
  AdminStudentProgress,
  AdminPaymentStatus_csv,

} = URL_MAP;

export const buildRoute = (...args) => {
  return args.reduce((prevRoute, item) => {
    return (
      prevRoute +
      (typeof item === "number" || item.indexOf("(") === -1 ? `/${item}` : item)
    );
  }, "");
};

export const applyRouteParams = (path, params) => {
  return generatePath(path, params);
};


const ROUTES = {
  ROOT: `/${BASE_URL}`,

  AdminLogin: buildRoute(AdminLogin),
  AdminForgotPassword: buildRoute(AdminForgotPassword),
  AdminUpdatepassword: buildRoute(AdminUpdatepassword),
  AdminDashboard: buildRoute(AdminDashboard),
  AdminUnlockModules: buildRoute(AdminUnlockModules),
  AdminPaymentStatus: buildRoute(AdminPaymentStatus),
  AdminRegistrationDetails: buildRoute(AdminRegistrationDetails),
  AdminCourseDetails: buildRoute(AdminCourseDetails),
  AdminCustomerDetails: buildRoute(AdminCustomerDetails),
  AdminProductDetails: buildRoute(AdminProductDetails),
  AdminStudentProgress: buildRoute(AdminStudentProgress),
  AdminPaymentStatus_csv: buildRoute(AdminPaymentStatus_csv),
   
};

export default ROUTES;
